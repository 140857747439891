import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import { motion } from "framer-motion"

// Hooks & Querys
import { QueryFragments } from "../Graphql/queryFragments" // eslint-disable-line

import { PageMeta } from "../components/pageMeta"

import { PortableTextBlock } from "../components/sanity"

// Animation Settings
import { leftVariants } from "../config/animation"

const AboutPage = ({ data: { page } }) => {
  const { meta, pageImage, _rawPageText } = page || {}

  return (
    <>
      <PageMeta {...meta} />
      <div className="grid md:grid-cols-2">
        <motion.div
          className="md:col-start-1 h-80 relative bg-black md:h-screen md:sticky md:top-0"
          variants={leftVariants}
        >
          {pageImage && pageImage.asset && (
            <Img
              fluid={pageImage.asset.fluid}
              className="h-full w-full top-0 left-0 object-cover z-0"
              style={{ position: "absolute", opacity: "0.5" }}
            />
          )}
        </motion.div>
        <motion.div
          className="bg-white px-10 py-16 overflow-auto md:col-start-2 md:h-screen md:px-20 md:pt-0"
          variants={leftVariants}
        >
          <div className="md:pt-40">
            <PortableTextBlock
              text={_rawPageText}
              className="prose md:prose-lg max-w-none"
            />
          </div>
        </motion.div>
      </div>
    </>
  )
}

export default AboutPage

export const pageQuery = graphql`
  query {
    page: sanityStandardPage(
      id: { eq: "-ab256ce4-4d4c-55c2-b7b7-91685e1d7a42" }
    ) {
      meta {
        ...MetaCardFields
      }
      _rawPageText(resolveReferences: { maxDepth: 10 })
      pageImage {
        asset {
          fluid(maxWidth: 1440) {
            ...GatsbySanityImageFluid
          }
        }
      }
    }
  }
`
